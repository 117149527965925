import React from 'react';
import * as style from './icon-card.module.scss';

type IconCardProps = {
  head?: string;
  text?: string;
  image?: any;
  children?: any;
  color:'pink'|'blue';
};

const IconCard = (props: IconCardProps) => {
  return (
    <div className={style.bgprimary02} style={{ borderColor:props.color==='pink' ? '#C636ED':'#0889dd80'}}>
      <div className="text-center">{props.image}</div>
      <p className={style.headerp}>{props.head}</p>
      <p className={style.brand}>{props.text}</p>
    </div>
  );
};
export default IconCard;
