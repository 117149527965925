import { graphql } from 'gatsby';
import * as React from 'react';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import BaseLayout from '../components/layouts/base-layout';
import { Container, Row, Col } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import * as style from '../styles/brand.module.scss';
import BrandLayout from '../components/layouts/brand-layout';
import IconCard from '../components/atomics/cards/icon-card';
import HeaderLayout from '../components/layouts/header-layout';
import { forwardToAppLink } from '../helpers/pathConverter';
import BannerImageCard from '../components/atomics/cards/banner-image-card';
import RightLine from '../images/right-blue-line.png';
import TertiaryButton from '../components/atomics/buttons/tertiary-button';
import CustomDots from '../components/atomics/buttons/custom-dots';
import LayerCard from '../components/atomics/cards/layer-card';
import Carousel from 'react-multi-carousel';
// markup
const FenomioPage = () => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const slides = [
    {
      id: 0,
      key: t('slides.register'),
      className: 'bgprimary02',
      text: t('process.text4'),
      textClass: style.layertext,
      image: language==='tr' ?
      <StaticImage className={style.verticalCarouselImage + ' ' + 'img-fluid'} src={'../images/bsignup.png'} alt={''}></StaticImage>
      :<StaticImage className={style.verticalCarouselImage + ' ' + 'img-fluid'} src={'../images/bsignup-en.png'} alt={''}></StaticImage>
    },
    {
      id: 1,
      key: t('slides.management'),
      className: 'bgsecondary02',
      text: t('process.text5'),
      textClass: style.layertext,
      image: language==='tr' ?
       <StaticImage className={style.verticalCarouselImage + ' ' + 'img-fluid'} src={'../images/baccount.png'} alt={''}></StaticImage>
      : <StaticImage className={style.verticalCarouselImage + ' ' + 'img-fluid'} src={'../images/baccount-en.png'} alt={''}></StaticImage>

    },
    {
      id: 2,
      key:  t('slides.self-service'),
      className: 'bgsecondary02',
      text: t('process.text6'),
      textClass: style.layertext,
      image:  language==='tr' ?
      <StaticImage className={style.verticalCarouselImage + ' ' + 'img-fluid'} src={'../images/bcampaigns.png'} alt={''}></StaticImage>
      :<StaticImage className={style.verticalCarouselImage + ' ' + 'img-fluid'} src={'../images/bcampaigns-en.png'} alt={''}></StaticImage>
    },
    {
      id: 3,
      key:  t('slides.payment-method'),
      className: 'bgsecondary02',
      text: t('process.text7'),
      textClass: style.layertext,
      image:  language==='tr' ?
      <StaticImage className={style.verticalCarouselImage + ' ' + 'img-fluid'} src={'../images/bpayment.png'} alt={''}></StaticImage>
      :<StaticImage className={style.verticalCarouselImage + ' ' + 'img-fluid'} src={'../images/bpayment-en.png'} alt={''}></StaticImage>
    },
    {
      id: 4,
      key: t('slides.start'),
      className: 'bgsecondary02',
      text: t('process.text8'),
      textClass: style.layertext,
      image: language==='tr' ?
       <StaticImage className={style.verticalCarouselImage + ' ' + 'img-fluid'} src={'../images/bfenomens.png'} alt={''}></StaticImage>
       :<StaticImage className={style.verticalCarouselImage + ' ' + 'img-fluid'} src={'../images/bfenomens-en.png'} alt={''}></StaticImage>
    }
  ];
  return (
    <BaseLayout>
      <HeaderLayout
        color="blue"
        head={t('brand.head1')}
        text={t('brand.text1')}
        backgroundImage={RightLine}
        image={
          <BannerImageCard
            backgroundColor={'#EBF7FF'}
            image={ language==='tr' ? <StaticImage src="../images/brand-image.png" alt={'banner'} className={'img-fluid'}></StaticImage>:<StaticImage src="../images/brand-image-en.png" alt={'banner'} className={'img-fluid'}></StaticImage>}
          ></BannerImageCard>
        }
      >
        <TertiaryButton
          style={{ width: 'max-content', paddingRight: '2.5rem', paddingLeft: '2.5rem' }}
          onClick={() => (window.location.href = forwardToAppLink('signup', language, 'brand'))}
          head={t('fenomio.brand-signup')}
        ></TertiaryButton>
      </HeaderLayout>
      <div>
        <BrandLayout
          company={t('brand.company')}
          count={t('brand.count')}
          testimonial={t('brand.testimonial')}
          text={t('brand.text')}
          color="blue"
        ></BrandLayout>
      </div>

      <div className={style.bannerC}>
        <div className={style.bgLineLeft}>
          <Row className="justify-content-md-center">
            <Col md="5">
              <h2 className="text-center">{t('fenomen.head2')}</h2>
            </Col>
          </Row>
          <Container>
            <Row className="mt-4 justify-content-md-center" style={{ gap: '1.5rem 0' }}>
              <Col md="4">
                <IconCard
                  color="blue"
                  head={t('ticoncards.head4')}
                  text={t('ticoncards.text4')}
                  image={<StaticImage className="img-fluid" src={'../images/social.png'} alt={''}></StaticImage>}
                ></IconCard>
              </Col>
              <Col md="4">
                <IconCard
                  color="blue"
                  head={t('ticoncards.head5')}
                  text={t('ticoncards.text5')}
                  image={<StaticImage className="img-fluid" src={'../images/wallet.png'} alt={''}></StaticImage>}
                ></IconCard>
              </Col>
              <Col md="4">
                <IconCard
                  color="blue"
                  head={t('ticoncards.head6')}
                  text={t('ticoncards.text6')}
                  image={<StaticImage className="img-fluid" src={'../images/demography.png'} alt={''}></StaticImage>}
                ></IconCard>
              </Col>
              <Col md="4">
                <IconCard
                  color="blue"
                  head={t('ticoncards.head7')}
                  text={t('ticoncards.text7')}
                  image={<StaticImage className="img-fluid" src={'../images/budget.png'} alt={''}></StaticImage>}
                ></IconCard>
              </Col>
              <Col md="4">
                <IconCard
                  color="blue"
                  head={t('ticoncards.head8')}
                  text={t('ticoncards.text8')}
                  image={<StaticImage className="img-fluid" src={'../images/graphic.png'} alt={''}></StaticImage>}
                ></IconCard>
              </Col>
              <Col md="4">
                <IconCard
                  color="blue"
                  head={t('ticoncards.head9')}
                  text={t('ticoncards.text9')}
                  image={<StaticImage className="img-fluid" src={'../images/bar.png'} alt={''}></StaticImage>}
                ></IconCard>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div className={style.bannerC + ' ' + style.mb}>
        <Row className="justify-content-center ">
          <Col md="5" xs="8">
            <h2 className="text-center">{t('reason.head1')}</h2>
          </Col>
        </Row>
        <Container>
          <Row className={style.verticalCarouselRow}>
            <Col lg={{ span: 9, offset: 3 }}>
              <Carousel
                additionalTransfrom={0}
                arrows={false}
                autoPlay
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="verticalCarouselPages"
                dotListClass="verticalDots"
                draggable
                customTransition="none"
                focusOnSelect={false}
                customDot={<CustomDots color="blue" slides={slides} />}
                infinite
                itemClass="verticalSlideItems"
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 1
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 1
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 1
                  }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                {slides.map((slide) => (
                  <Row key={slide.key} style={{ display: 'flex', justifyContent: 'space-between', height: '100%', alignContent: 'space-between' }}>
                    
                    <Col lg={{ span: 6 }} style={{ display: 'flex', alignItems: 'center', padding: '2rem' }}>
                      <LayerCard className={slide.className} textStart={slide.text} textClass={slide.textClass} />
                    </Col>
                    <Col lg={6} className={style.verticalCarouselImageContainer}>
                      {slide.image}
                    </Col>
                  </Row>
                ))}
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </BaseLayout>
  );
};

export default FenomioPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
